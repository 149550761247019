import * as React from "react"
import { graphql, Link } from "gatsby"
import { ImgixGatsbyImage } from '@imgix/gatsby'

import Layout from "../components/layout.js"
import Seo from "../components/seo.js"

const IndexPage = ({ data }) => {
  return (
        <Layout>
          <Seo />
        <section className="hero">
          <figure>
            <img src="/images/hero.jpg" alt="" />
          </figure>
          <div className="catch">
            <h1>There is no love sincerer than<br /> the love of food.</h1>
            <p>test食物を愛するよりも誠実な愛はない ― バーナード・ショー</p>
          </div>
          <div className="wave">
            <img src="/images/wave.svg" alt="" />
          </div>
        </section>
        <section className="food">
          <div className="container">
            <h2 className="bar">Food <span>Essence</span></h2>
            <div className="details">
              <div className="detail">
                <figure>
                  <img src="/images/fruit.jpg" alt="" />
                </figure>
                <h3>フルーツ</h3>
                <p>FRUIT</p>
                <p>甘くてすっぱくておいしい果実たち。<br />旬のフルーツを満喫します。</p>
              </div>
              <div className="detail">
                <figure>
                  <img src="/images/grain.jpg" alt="" />
                </figure>
                <h3>穀物</h3>
                <p>GRAIN</p>
                <p>食事の基本となる穀物。<br />毎日の活動のエネルギー源になります。</p>
              </div>
              <div className="detail">
                <figure>
                  <img src="/images/beverage.jpg" alt="" />
                </figure>
                <h3>飲み物</h3>
                <p>BEVERAGE</p>
                <p>リラックスするのに欠かせない飲み物。<br />お気に入りの一杯はありますか？</p>
              </div>
            </div>
          </div>
        </section>
        <section className="photo">
          <h2 className="sr-only">Photo</h2>
          <figure>
            <img src="/images/berry.jpg" alt="赤く熟したベリー" />
          </figure>
        </section>
        <section>
          <div className="container">
            <h2 className="sr-only">RECENT POSTS</h2>
            <div className="posts">
              {data.allMicrocmsBlog.edges.map(({ node }) => (
                <article className="post" key={node.id}>
                  <Link to={`/blog/post/${node.slug}/`}>
                    <figure>
                    <ImgixGatsbyImage
                      src={node.eyecatch.url}
                      imgixParams={{ auto: ["format", "compress"] }}
                      layout="constrained"
                      width={573}
                      sourceWidth={node.eyecatch.width}
                      sourceHeight={node.eyecatch.height}
                      style={{ height: "100%" }}
                      />
                    </figure>
                    <h3>{node.title}</h3>
                  </Link>
                </article>
              ))}
            </div>
          </div>
        </section>
        </Layout>
  )
}


export const query = graphql`
query {
  allMicrocmsBlog(
    sort: { order: DESC, fields: publishDate },
    skip: 0,
    limit: 4
  ) {
    edges {
      node {
        title
        id
        slug
        eyecatch {
          height
          url
          width
        }
      }
    }
  }
}`

export default IndexPage
